<template>
    <div class="view">
        <div class="titleBox">
            <span class="title">
                {{lang['title']}}
            </span>
        </div>
        <div class="toolsBox">
        <span class="info link">
            <el-icon :size="13"><avatar /></el-icon>
            {{lang['username']}}:{{userName}}
        </span>
            <el-link class="link" @click="jumpIndex">{{lang['index']}}</el-link>
            <el-link
                class="link"
                @click="this.$router.push({name:'agent.setSelfNewPass'})"
                v-if="isOfficial"
            >{{lang['setSelfNewPass']}}</el-link>
            <el-link class="link" @click="loginOut">{{lang['loginOut']}}</el-link>
        </div>
    </div>
</template>

<script>
import User from "../../utils/User";
import {IsOfficial} from "../../utils/LayoutNavBar";
export default {
    name: "memberHeader",
    components:{},
    data(){
      return {
          userName:'null',
          lang:this.$lang.getLang('layout','memberHeader'),
          isOfficial:IsOfficial(),
      }
    },
    methods:{
        /**
         * 个人中心[返回首页]
         */
        jumpIndex(){
            this.$router.push({name:'member.index',})
        },
        /**
         * 退出登录
         */
        loginOut(){
            this.$cookie.setToken('');
            let params = {};
            let version = this.$cookie.getCookie('version');
            if (version == 1){
                params.version = 'c';
            }else if (version == 2){
                params.version = 'y';
            }else {
                params.version = 'error';
            }
            this.$router.push({name:'login',params})
        },
        /**
         * 获取已登录账号
         */
        getLoginUserName(){
            let str = User.getUserName();
            this.userName = str == null ? 'error null' : str;
        }
    },
    mounted() {
        this.getLoginUserName();
    }
}
</script>

<style scoped>
.view{
    width: 100%;
}

.titleBox{
    /*display: inline;*/
    float: left;
}

.title{
    font-size: 20px;
    color: #F56C6C;
    margin-left: 10px;
}

.toolsBox{
    float: right;
    text-align:right;

}

.info{
    color: #FFFFFF;
    font-size: 14px;
}

.link{
    margin-left: 20px;
    color: #FFFFFF;

}

</style>
<template>
    <div class="common-layout">
        <el-container>
            <el-header>
                <memberHeader></memberHeader>
            </el-header>
            <el-container>

                <el-aside style="width: 200px;">
                    <NavBar @newPage="openNewUrl"></NavBar>
                </el-aside>

                <el-main>
                    <el-config-provider :locale="locale">
                    <router-view></router-view>
                    </el-config-provider>
                </el-main>
            </el-container>
        </el-container>
    </div>

</template>

<script>
import memberHeader from "./components/memberHeader";
import NavBar from "./components/NavBar";
export default {
    name: "Member",
    components:{
        memberHeader,NavBar
    },
    data(){
        return {
            timer:null, //定时器名称
            //element plus 多语言[国际化]
            locale:this.$lang.getElementPlusLang(),
        }
    },
    methods:{
        /**
         * 跳转到新页面
         * @param obj 新页面参数[对象]
         */
        openNewUrl(obj){
            //定义附带参数
            let params  = {};
            if (obj.name != null){
                if (obj.name == 'key.keyList'){
                    //如果是卡密列表,需要附带参数
                    params = {type:1,status:3,agent:'self'};//默认显示全部注册码,[显示
                }
                this.$router.push({name:obj.name,params})
            }
            // console.log('openNewUrl',obj);
        },
        //检测Token是否失效
        checkToken(){
            this.$api.post('Api.Auth/checkToken').then((res)=>{
                let bool = this.$helper.checkRes(res);
                // console.log('令牌存活检测完毕',new Date().toLocaleTimeString())
                if (!bool){
                    //令牌失效,跳转到首页重新登录
                    console.log('令牌已失效');
                    this.jumpIndex();
                }
            })
        },
        //返回首页,并重新登录
        jumpIndex(){
            this.$alert('The token has expired. Please login again');
            this.$cookie.setToken('');
            let params = {};
            let version = this.$cookie.getCookie('version');
            if (version == 1){
                params.version = 'c';
            }else if (version == 2){
                params.version = 'y';
            }else {
                params.version = 'error';
            }
            this.$router.push({name:'login',params})
        }
    },
    mounted() {
        this.checkToken()
        //创建个定时循环任务,检测Token是否可用
        this.timer = setInterval(() => {
            setTimeout(this.checkToken, 0)
        }, 1000*30);
    },
    beforeUnmount(){
        //组件卸载前清除定时器
        clearInterval(this.timer);
        this.timer = null
    }
}
</script>

<style scoped>
.common-layout .el-header,
.common-layout .el-footer {
    background-color: #303133;
    color: var(--el-text-color-primary);
    /*text-align: center;*/
    line-height: 60px;
}
.common-layout .el-footer {
    line-height: 60px;
}

.common-layout .el-aside {
    background-color: #d3dce6;
    color: var(--el-text-color-primary);
    text-align: center;
    line-height: 200px;

}

.common-layout .el-main {
    background-color: #E4E7ED;
    color: var(--el-text-color-primary);
    text-align: center;
    /*高度*/
    /*line-height: 700px;*/
}

.common-layout > .el-container {
    margin-bottom: 40px;
}

.common-layout .el-container:nth-child(5) .el-aside,
.common-layout .el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.common-layout .el-container:nth-child(7) .el-aside {
    line-height: 320px;
}
</style>